import Enkelhet from '../images/enkelhet.png';
import Beredskap from '../images/beredskap.png';
import Trygghet from '../images/trygghet.png';

import Munskydd from '../images/prod_mask.png';
import Nitril from '../images/prod_handskar.png';
import Visir from '../images/prod_visir.png';
import SkyddsOverall from '../images/prod_hazmat.png';
import HandSprit from '../images/prod_alcogel.png';
import Ytdesinfektion from '../images/prod_tablett.png';
import MedBag from '../images/prod_medbag.png';

import { ITestimonialCard } from '../components/atoms/interfaces';
import { IInfoCard } from '../components/molecules/infoCard';

export const infoCards: IInfoCard[] = [
  {
    title: 'Trygghet',
    description:
      'Genom att använda våra produkter skyddar du dig själv och andra genom att begränsa smittspridning i samhället.',
    image: Trygghet
  },
  {
    title: 'Beredskap',
    description:
      'Din panBox finns tillgänglig på ditt privata beredskapslager hos oss. Det garanterar dig skyddsprodukter när du verkligen behöver det – utan att ta onödig plats i ditt hem.',
    image: Beredskap
  },
  {
    title: 'Snabbt & Enkelt',
    description:
      'På Mina Sidor kan du enkelt lägga till fler panBox till nära och kära. Om du behöver beställa hem din panBox skickar vi det inom 24 timmar.',
    image: Enkelhet
  }
];

export const productCards: IInfoCard[] = [
  {
    title: 'Munskydd',
    description:
      'Munskydd som används för att begränsa spridning av smitta. Fästs enkelt runt öronen med hjälp av elastiska band som håller munskyddet på plats framför näsan och munnen. Skyddet är framtaget för att fånga mikroorganismer i utandningsluften och skydda omgivningen från dessa.',
    image: Munskydd,
    productInfo: '1 förpackning. Innehåll: 10 st munskydd.',
    productSpecification: [
      'Munskydd för engångsbruk',
      'Minskar risken för spridning av smitta',
      'Elastiska band som du enkelt fäster runt öronen'
    ]
  },
  {
    title: 'Nitrilhandskar',
    description:
      'Smidiga, bekväma och puderfria handskar i nitril för engångsbruk. Handskarna tål stora påfrestningar och ger ett säkert skydd mot virus, bakterier och smittsamma sjukdomar. Handskarna är helt fria från latex och är ett perfekt alternativ för dem som har latexallergi typ I.',
    image: Nitril,
    productInfo: '1 förpackning. Innehåll: 10 st handskar.',
    productSpecification: [
      'Starka handskar i nitril för engångsbruk',
      'Ett säkert skydd mot smittosamma sjukdomar',
      'Latexfria'
    ]
  },
  {
    title: 'Ansiktsvisir',
    description:
      'Visir av transparent PET-material som effektivt skyddar ansiktet mot luftburna partiklar. Täcker hela ansiktet och håller ögon, näsa och mun fria från damm och vätskestänk. Ergonomiskt utformat med pannband av skum som gör att visiret sitter bekvämt även under längre användning. Används med fördel tillsammans med munskydd.\n',
    image: Visir,
    productInfo: '1 förpackning. Innehåll: 1 st ansiktsvisir',
    productSpecification: [
      'Ansiktsvisir för engångsbruk',
      'Skyddar hela ansiktet mot stänk',
      'Bekväm passform med pannband i skum'
    ]
  },
  {
    title: 'Skyddsoverall',
    description:
      'Overall utformad för att skydda hela kroppen mot luftburna partiklar och vätskestänk. Tillverkad av lågluddande nonwoven-material och har god passform. Overallen har tejpade sömmar som ökar hållbarheten och minskar risken för revor. Antistatisk beläggning som minskar urladdning av statisk elektricitet. Används med fördel tillsammans med munskydd och ansiktsvisir.',
    image: SkyddsOverall,
    productInfo: '1 förpackning. Innehåll: 1 st skyddsoverall',
    productSpecification: [
      'Skyddsoverall för engångsbruk',
      'Skyddar kroppen från stänk och vätskor'
    ]
  },
  {
    title: 'Ytdesinfektion',
    description:
      'Mycket effektiv desinfektion i tablettform. Löses enkelt i vatten och används som färdigblandad desinfektion. För alla slags ytor, även ytor som är i direkt kontakt med livsmedel. Mycket effektiv som desinfektionsmedel mot ett stort antal mikroorganismer och fungerar I alla vattenhårdheter.',
    image: Ytdesinfektion,
    productInfo: '1 förpackning. Innehåll: 2 st tabletter (2 x 75 cl)',
    productSpecification: [
      'Effektivt desinfektionmedel',
      'Rengör alla ytor',
      'Klorfria'
    ]
  },
  {
    title: 'Handdesinfektion',
    description:
      'Ger ett effektivt skydd mot bakterier och virus. Skonsam mot huden, kladdar inte och ger effekt direkt. Praktisk flaska som är lätt att ha med sig.',
    image: HandSprit,
    productInfo: '1 förpackning. Innehåll: 100 ml',
    productSpecification: [
      'Handdesinfektion i praktisk förpackning',
      'Skyddar effektivt mot bakterier och virus',
      'Enkel att ha med sig'
    ]
  },
  {
    title: 'Första hjälpen väska',
    description:
      'Liten med välutrustad första hjälpen väska som innehåller det nödvändigaste. Bra att ha för att ta hand om små sår, bett och lindrigare skador. Fodral av slitstarkt nylon som skyddar mot damm och smuts.',
    image: MedBag,
    productInfo: '1 förpackning. Innehåll:',
    productSpecification: ['Plåster', 'Gasbinda', 'Kompress mm.']
  }
];

export const testimonialCards: ITestimonialCard[] = [
  {
    testimonial:
      'Det känns mycket tilltalande att ha ett privat beredskapslager och veta att jag är förberedd. Det känns väldigt tryggt.',
    by: 'DANIEL NILSSON, ELEKTRIKER'
  },
  {
    testimonial:
      'Vi har skapat panBox för att ge allmänheten ett enkelt alternativ att skydda människor i deras omedelbara närhet. Vi tror att panBox kan bidra till stor samhällsnytta.',
    by: 'ERIK RYDHOLM, VD PANBOX'
  },
  {
    testimonial:
      'Jag tycker panBox är en jättebra idé! Allt som hjälper till att förhindra spridning av bakterier och virus inom landet är en verklig nytta för samhället och för oss medborgare.',
    by: 'KARL LINDSTEDT, FÖRETAGSLEDARE'
  }
];
