import React from 'react';
import Card from '../atoms/card';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import styled, { css } from 'styled-components';
import ListItem from '../atoms/listItem';

interface ICardType {
  type?: 'product';
}

export interface IInfoCard {
  title: string;
  description: string;
  image: string;
  productInfo?: string;
  productSpecification?: string[];
  alt?: string;
  height?: string;
  type?: 'product';
}

const InfoWrapper = styled(Card)`
  height: 95%;
  border-radius: 12px;
`;

const CardInner = styled.div<ICardType>`
  padding: 1rem;
  padding-bottom: 3rem;
`;

const CardImage = styled.img<ICardType>`
  width: 100%;
  margin: auto;
  ${(props) =>
    props.type === 'product' &&
    css`
      object-fit: scale-down;
      max-height: 200px;
      padding-top: 2rem;
    `};
`;

const CardListWrapper = styled.ul`
  list-style: none;
`;

const ProductInfo = styled(Typography)`
  font-weight: bold;
`;

const InfoCard: React.FC<IInfoCard> = ({
  title,
  description,
  image,
  productInfo,
  productSpecification,
  alt,
  type
}) => {
  return (
    <InfoWrapper>
      <CardImage type={type} src={image} alt={alt} />
      <CardInner type={type}>
        <Typography color="primary" variant="h3">
          {title}
        </Typography>
        <Spacer spacing={0.5} />
        <Typography color="textPrimary">{description}</Typography>
        {productInfo && (
          <ProductInfo color="textPrimary">{productInfo}</ProductInfo>
        )}
        {productSpecification && (
          <CardListWrapper>
            {productSpecification.map((item) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </CardListWrapper>
        )}
      </CardInner>
    </InfoWrapper>
  );
};

export default InfoCard;
