import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import React from 'react';
import Typography from '../atoms/typography';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import Prices from '../../services/prices';

const circleHeight = 130;

const Circle = styled.div`
  box-shadow: 0 0.25em 0.5rem rgb(0 0 0 / 50%);
  display: flex;
  background-color: ${theme.colors.secondary};
  height: ${`${circleHeight}px`};
  width: ${`${circleHeight}px`};
  border-radius: ${`${circleHeight / 2}px`};
  justify-content: center;
  align-items: center;
  ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? css`
          height: ${`${circleHeight * 0.7}px`};
          width: ${`${circleHeight * 0.7}px`};
          border-radius: ${`${(circleHeight / 2) * 0.7}px`};
        `
      : css`
          height: ${`${circleHeight}px`};
          width: ${`${circleHeight}px`};
          border-radius: ${`${circleHeight / 2}px`};
        `}
`;

const CircleInner = styled.div`
  padding-left: 5px;
  text-align: center;
`;

const StyledTitle = styled(Typography)`
  font-size: 2.5rem;
`;

interface IPriceTag {
  title?: string;
}

const PriceTag: React.FC<IPriceTag> = ({ title = Prices.SUBSCRIPTION }) => {
  const { IS_MOBILE } = useScreenClassHelper();
  return (
    <Circle isMobile={IS_MOBILE}>
      <CircleInner>
        <StyledTitle color="textSecondary" variant="h2">
          {`${title}:-`}
        </StyledTitle>
        <Typography color="textSecondary">/månad</Typography>
      </CircleInner>
    </Circle>
  );
};

export default PriceTag;
