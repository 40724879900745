import React from 'react';
import styled from 'styled-components';

const StyledLi = styled.li`
  margin-left: 1rem;
  &:before {
    content: '\\2022';
    color: ${(props) => props.theme.colors.secondary};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const ListItem: React.FC<React.LiHTMLAttributes<HTMLLIElement>> = (props) => (
  <StyledLi {...props}>{props.children}</StyledLi>
);

export default ListItem;
